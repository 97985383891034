.news-time-setting-root {
  margin-top: 60px;
}
.news-time-setting-root .news-time-title {
  flex: 1;
  height: 29px;
  font-size: 20px;
  font-weight: normal;
  font-family: "AppleSDGothicNeo", sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: left;
  color: #fff;
  background-color: #1d1d39;
}
.news-time-setting-root .time-input-box {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  height: 56px;
  max-width: 520px;
  background-color: #373753;
  border-radius: 4px;
  padding: 12px;
}
.news-time-setting-root .time-input-box .input-container {
  display: flex;
  flex: 1;
}
.news-time-setting-root .time-input-box .input-container .time-input-input {
  flex: 1;
  font-size: 18px;
  font-weight: normal;
  font-family: "AppleSDGothicNeo", sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  max-width: 520px;
  text-align: left;
  border-radius: 4px;
  background-color: #373753;
  color: #fff;
}
.news-time-setting-root .time-input-box .time-input-button {
  display: flex;
  flex-direction: column;
}
.news-time-setting-root .time-input-box .time-input-button .up-btn {
  flex: 1;
  height: 28px;
  border-radius: 4px;
  background-color: #373753;
  cursor: pointer;
}
.news-time-setting-root .time-input-box .time-input-button .down-btn {
  flex: 1;
  height: 28px;
  border-radius: 4px;
  background-color: #373753;
  cursor: pointer;
}/*# sourceMappingURL=newstime.setting.styles.css.map */