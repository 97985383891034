@-webkit-keyframes newsLineChange {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes newsLineChange {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #1d1d39;
  height: 90px;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
}
@media (max-width: 900px) {
  .footer {
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.footer .text-wrapper {
  margin-top: 35px;
  margin-bottom: 34px;
  height: 21px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
@media (max-width: 900px) {
  .footer .text-wrapper {
    height: 17px;
    margin-top: 20px;
    margin-bottom: 19px;
  }
}
.footer .text-wrapper .news-text {
  color: #ffffff;
  font-family: "AppleSDGothicNeo-Regular", sans-serif;
  font-size: 18px;
  letter-spacing: -0.45px;
}
@media (max-width: 900px) {
  .footer .text-wrapper .news-text {
    display: none;
  }
}
.footer .text-wrapper .bar {
  width: 1px;
  margin-left: 15px;
  margin-right: 15px;
}
@media (max-width: 900px) {
  .footer .text-wrapper .bar {
    display: none;
  }
}
.footer .text-wrapper .text-container {
  display: flex;
  -webkit-animation: newsLineChange 0.3s ease-in;
          animation: newsLineChange 0.3s ease-in;
}
.footer .text-wrapper .text-container .warning-img {
  margin-right: 6px;
  width: 20px;
  height: 20px;
}
@media (max-width: 900px) {
  .footer .text-wrapper .text-container .warning-img {
    width: 18px;
    height: 18px;
  }
}
.footer .text-wrapper .text-container .name-text {
  color: #fed32f;
  letter-spacing: -0.45px;
  font-family: "AppleSDGothicNeo", sans-serif;
  font-size: 18px;
  text-align: left;
}
.footer .text-wrapper .text-container .name-text.frost {
  color: #dd1b52;
}
.footer .text-wrapper .text-container .name-text.wet {
  color: #ea5e35;
}
.footer .text-wrapper .text-container .name-text.normal {
  color: #5aa664;
}
@media (max-width: 900px) {
  .footer .text-wrapper .text-container .name-text {
    font-size: 14px;
    min-width: 24px;
  }
}
.footer .text-wrapper .text-container .colon-text {
  color: #ffffff;
  padding-left: 5px;
  padding-right: 5px;
  font-family: "AppleSDGothicNeo", sans-serif;
  font-size: 18px;
}
@media (max-width: 900px) {
  .footer .text-wrapper .text-container .colon-text {
    font-size: 14px;
  }
}
.footer .text-wrapper .text-container .location-text {
  color: #ffffff;
  letter-spacing: -0.45px;
  font-family: "AppleSDGothicNeo", sans-serif;
  font-size: 18px;
}
@media (max-width: 900px) {
  .footer .text-wrapper .text-container .location-text {
    font-size: 14px;
    overflow: hidden;
  }
}
.footer .temp-wrapper {
  margin-top: 26px;
  margin-bottom: 25px;
  display: flex;
  height: 39px;
}
@media (max-width: 900px) {
  .footer .temp-wrapper {
    margin-top: 14px;
    margin-bottom: 13px;
    min-width: 61px;
    height: 29px;
  }
}
.footer .temp-wrapper .temp-img {
  margin-top: 6px;
  width: 20px;
  height: 20px;
}
@media (max-width: 900px) {
  .footer .temp-wrapper .temp-img {
    margin-top: 4px;
    width: 18px;
    height: 18px;
  }
}
.footer .temp-wrapper .temp-text {
  margin-left: 6px;
  margin-right: 7px;
  color: #fed32f;
  font-size: 32px;
  letter-spacing: -0.8px;
  height: 39px;
  font-family: "AppleSDGothicNeo", sans-serif;
}
@media (max-width: 900px) {
  .footer .temp-wrapper .temp-text {
    margin-right: 0;
    font-size: 24px;
    height: 29px;
  }
}
.footer .temp-wrapper .temp-unit-text {
  width: 14px;
  color: #fed32f;
  letter-spacing: -0.45px;
  color: #fed32f;
  font-family: "AppleSDGothicNeo", sans-serif;
  font-size: 18px;
}
@media (max-width: 900px) {
  .footer .temp-wrapper .temp-unit-text {
    width: 12px;
    font-size: 14px;
  }
}/*# sourceMappingURL=cfooter.styles.css.map */