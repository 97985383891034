@import '../../constants';

$main: #1d1d39;

.setting {
  display: flex;
  height: calc(100vh - 220px); //viewport 높이의 100%임
  min-height: 460px;

  @include mobile {
    height: calc(100vh - 112px);
  }
  flex: 1;
  background-color: $main;
  //flex-direction: column;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    width: 520px;
    //height: 100vh;
    background-color: $main;
    //padding: 60px;

    .title {
      // flex: 1;
      height: 30px;
      font-size: 32px;
      font-weight: 500;
      // padding-bottom: 60px;
      font-family: 'AppleSDGothicNeo', sans-serif;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -1.6px;
      text-align: left;
      color: #fff;
      background-color: $main;
    }

    @include mobile {
      margin-left: 16px;
      margin-right: 16px;
    }

    .btn-container {
      margin-top: 60px;
      display: flex;
      flex-direction: row;

      .cancel {
        flex: 1;
        height: 56px;
        margin-right: 8px;
        background-color: $main;
        font-size: 18px;
        font-weight: normal;
        font-family: 'AppleSDGothicNeo', sans-serif;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.9px;
        color: white;
        border: solid 1px #fed32f;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: lighten($main, 10%);
          color: white;
        }
        &:active {
          background: darken($main, 10%);
        }
      }
      .save {
        flex: 1;
        height: 56px;
        background-color: #fed32f;
        font-size: 18px;
        font-weight: normal;
        font-family: 'AppleSDGothicNeo', sans-serif;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.9px;
        color: black;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: lighten(#fed32f, 10%);
          color: black;
        }
        &:active {
          background: darken(#fed32f, 10%);
        }
      }
    }
  }
}
