@import '../../color.scss';
@import '../../constants.scss';

.map-root {
  display: flex;
  height: calc(100vh - 220px);
  min-height: 440px;

  @include mobile {
    height: calc(100vh - 112px);
  }

  .map-section {
    display: flex;
    flex: 1;
    flex-direction: column;
    border-radius: 4px;

    #map {
      width: 100%;
      height: 100%;
    }

    .marker-label {
      width: 79px;
      height: 32px;
      border-radius: 16px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: $white;
      font-size: 14px;
      font-family: AppleSDGothicNeo-Regular;
      text-align: center;

      &.active {
        border: solid 3px $yellow;
      }

      &.inactive {
      }
    }

    .up-level-btn {
      position: absolute;
      cursor: pointer;
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 8px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      bottom: 180px;
      right: 40px;
      background: $white;
      z-index: 1;

      @include mobile {
        bottom: 144px;
        right: 16px;
      }
    }

    .down-level-btn {
      position: absolute;
      cursor: pointer;
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 8px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      bottom: 128px;
      right: 40px;
      background: $white;
      z-index: 1;

      @include mobile {
        bottom: 92px;
        right: 16px;
      }
    }
  }
}
