.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 130px;
  padding-left: 40px;
  padding-right: 40px;
  background-color: #1d1d39;
}
@media (max-width: 900px) {
  .header {
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.header .logo-wrapper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: end;
}
.header .logo-wrapper .logo {
  height: 38px;
  width: 204px;
}
@media (max-width: 900px) {
  .header .logo-wrapper .logo {
    height: 24px;
    width: 123px;
  }
}
.header .logo-wrapper .logo-text {
  color: #ffffff;
  padding-left: 16px;
  font-size: 18px;
}
@media (max-width: 900px) {
  .header .logo-wrapper .logo-text {
    display: none;
  }
}
.header .nav {
  display: flex;
  justify-content: space-between;
}
.header .nav .nav-img-wrapper {
  cursor: pointer;
}
.header .nav .nav-img-wrapper .nav-img {
  width: 32px;
  height: 32px;
  margin-left: 36px;
}
@media (max-width: 900px) {
  .header .nav .nav-img-wrapper .nav-img {
    width: 28px;
    height: 28px;
    margin-left: 16px;
  }
}/*# sourceMappingURL=gnb.styles.css.map */