.setting {
  display: flex;
  height: calc(100vh - 220px);
  min-height: 460px;
  flex: 1;
  background-color: #1d1d39;
  align-items: center;
  justify-content: center;
}
@media (max-width: 900px) {
  .setting {
    height: calc(100vh - 112px);
  }
}
.setting .container {
  display: flex;
  flex-direction: column;
  width: 520px;
  background-color: #1d1d39;
}
.setting .container .title {
  height: 30px;
  font-size: 32px;
  font-weight: 500;
  font-family: "AppleSDGothicNeo", sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.6px;
  text-align: left;
  color: #fff;
  background-color: #1d1d39;
}
@media (max-width: 900px) {
  .setting .container {
    margin-left: 16px;
    margin-right: 16px;
  }
}
.setting .container .btn-container {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
}
.setting .container .btn-container .cancel {
  flex: 1;
  height: 56px;
  margin-right: 8px;
  background-color: #1d1d39;
  font-size: 18px;
  font-weight: normal;
  font-family: "AppleSDGothicNeo", sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  color: white;
  border: solid 1px #fed32f;
  border-radius: 4px;
  cursor: pointer;
}
.setting .container .btn-container .cancel:hover {
  background: #2e2e5b;
  color: white;
}
.setting .container .btn-container .cancel:active {
  background: #0c0c17;
}
.setting .container .btn-container .save {
  flex: 1;
  height: 56px;
  background-color: #fed32f;
  font-size: 18px;
  font-weight: normal;
  font-family: "AppleSDGothicNeo", sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  color: black;
  border-radius: 4px;
  cursor: pointer;
}
.setting .container .btn-container .save:hover {
  background: #fede62;
  color: black;
}
.setting .container .btn-container .save:active {
  background: #f9c501;
}/*# sourceMappingURL=setting.styles.css.map */