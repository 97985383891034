$main: #1d1d39;
.news-time-setting-root {
  margin-top: 60px;

  .news-time-title {
    flex: 1;
    height: 29px;
    font-size: 20px;
    font-weight: normal;
    font-family: 'AppleSDGothicNeo', sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1px;
    text-align: left;
    color: #fff;
    background-color: $main;
  }

  .time-input-box {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    height: 56px;
    max-width: 520px;
    background-color: #373753;
    border-radius: 4px;
    padding: 12px;

    .input-container {
      display: flex;
      flex: 1;
      .time-input-input {
        flex: 1;
        font-size: 18px;
        font-weight: normal;
        font-family: 'AppleSDGothicNeo', sans-serif;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.9px;
        max-width: 520px;
        text-align: left;
        border-radius: 4px;
        background-color: #373753;
        color: #fff;
      }
    }

    .time-input-button {
      display: flex;
      flex-direction: column;

      .up-btn {
        flex: 1;
        //width: 28px;
        height: 28px;
        border-radius: 4px;
        //padding: 14px 30px 14px 0px;
        background-color: #373753;
        cursor: pointer;
      }
      .down-btn {
        flex: 1;
        //width: 28px;
        height: 28px;
        border-radius: 4px;
        //padding: 14px 12px 14px 12px;
        background-color: #373753;
        cursor: pointer;
      }
    }
  }
}
