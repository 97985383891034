.content-root {
  background-color: #1d1d39;
  height: calc(100vh - 220px);
  min-height: 300px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
@media (max-width: 900px) {
  .content-root {
    height: calc(100vh - 112px);
  }
}
.content-root .listHeader {
  height: 130px;
}
.content-root .listFooter {
  height: 90px;
}
.content-root .listMain {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
}
.content-root .listMain .data-container {
  width: 100%;
  height: 56px;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 900px) {
  .content-root .listMain .data-container {
    height: auto;
  }
}
.content-root .listMain .data-container .ice-data {
  display: flex;
  align-items: center;
  flex: 10;
  margin: 0px 8px 8px 40px;
  border-radius: 4px;
  background-color: #373753;
}
@media (max-width: 900px) {
  .content-root .listMain .data-container .ice-data {
    flex: 1 1;
    flex-basis: 100%;
    margin: 0px 16px 8px 16px;
    height: 56px;
  }
}
.content-root .listMain .data-container .ice-data .notice-image {
  height: 18px;
  width: 18px;
  margin-left: 12px;
  margin-right: 6px;
}
.content-root .listMain .data-container .ice-data .ice-data-detail {
  font-size: 18px;
  color: #fff;
  letter-spacing: -0.9px;
}
.content-root .listMain .data-container .ice-data .ice-data-detail .ice-data-title.frost {
  color: #dd1b52;
}
.content-root .listMain .data-container .ice-data .ice-data-detail .ice-data-title.normal {
  color: #5aa664;
}
.content-root .listMain .data-container .ice-data .ice-data-detail .ice-data-title.wet {
  color: #ea5e35;
}
@media (max-width: 900px) {
  .content-root .listMain .data-container .ice-data .ice-data-detail {
    font-size: 16px;
    letter-spacing: -0.8px;
  }
}
.content-root .listMain .data-container .calendar {
  display: flex;
  flex: 7;
  margin: 0px 48px 8px 0px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #1d1d39;
}
@media (max-width: 900px) {
  .content-root .listMain .data-container .calendar {
    flex: 1 1;
    flex-basis: 100%;
    margin: 0px 16px 8px 16px;
    height: 56px;
  }
}
.content-root .listMain .data-container .calendar .calendar-input {
  display: flex;
  flex: 1;
}
@media (max-width: 900px) {
  .content-root .listMain .data-container .calendar .calendar-input {
    margin-right: 0;
  }
}
.content-root .listMain .data-container .calendar .calendar-input input[type=date]::-webkit-datetime-edit-text {
  -webkit-appearance: none;
  display: none;
}
.content-root .listMain .data-container .calendar .calendar-input input[type=date]::-webkit-datetime-edit-month-field {
  -webkit-appearance: none;
  display: none;
}
.content-root .listMain .data-container .calendar .calendar-input input[type=date]::-webkit-datetime-edit-day-field {
  -webkit-appearance: none;
  display: none;
}
.content-root .listMain .data-container .calendar .calendar-input input[type=date]::-webkit-datetime-edit-year-field {
  -webkit-appearance: none;
  display: none;
}
.content-root .listMain .data-container .calendar .calendar-input .inputBox-label {
  display: flex;
  flex: 1;
  position: relative;
}
.content-root .listMain .data-container .calendar .calendar-input .inputBox-label .inputBox {
  flex: 1;
  border-radius: 4px;
  padding-right: 24px;
  background-color: #373753;
}
.content-root .listMain .data-container .calendar .calendar-input .inputBox-label .inputBox::-webkit-calendar-picker-indicator {
  background: transparent;
  color: transparent;
  cursor: pointer;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
}
.content-root .listMain .data-container .calendar .calendar-input .inputBox-label .imageDate {
  position: absolute;
  bottom: 12px;
  right: 14px;
  width: 24px;
  height: 24px;
  pointer-events: none;
}
@media (max-width: 900px) {
  .content-root .listMain .data-container .calendar .calendar-input .inputBox-label .imageDate {
    bottom: 16px;
  }
}
.content-root .listMain .data-container .calendar .calendar-input .inputBox-label .choicedDate {
  position: absolute;
  left: 12px;
  bottom: 12px;
  font-size: 18px;
  letter-spacing: -0.9px;
  color: #fff;
  pointer-events: none;
}
@media (max-width: 900px) {
  .content-root .listMain .data-container .calendar .calendar-input .inputBox-label .choicedDate {
    font-size: 16px;
    letter-spacing: -0.8px;
    bottom: 18px;
  }
}
.content-root .listMain .data-container .calendar .calendar-searchButton {
  width: 76px;
  border-radius: 4px;
  background-color: #373753;
  font-size: 18px;
  letter-spacing: -0.9px;
  color: #fed32f;
}
@media (max-width: 900px) {
  .content-root .listMain .data-container .calendar .calendar-searchButton {
    display: none;
  }
}
.content-root .listMain .detail-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 900px) {
  .content-root .listMain .detail-container {
    height: auto;
  }
}
.content-root .listMain .detail-container .detailImage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 10;
  margin: 0px 8px 8px 40px;
  border-radius: 4px;
  background-color: #373753;
  overflow: hidden;
}
.content-root .listMain .detail-container .detailImage .image-fill {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 900px) {
  .content-root .listMain .detail-container .detailImage {
    flex: 1 1;
    flex-basis: 100%;
    margin: 0px 16px 8px 16px;
    height: 242px;
  }
  .content-root .listMain .detail-container .detailImage .imageEmpty {
    width: 64px;
    height: 47px;
  }
}
.content-root .listMain .detail-container .table-container {
  display: flex;
  flex-direction: column;
  flex: 7;
  margin: 0px 48px 8px 0px;
  border-radius: 4px;
  background-color: #373753;
  overflow: hidden;
}
@media (max-width: 900px) {
  .content-root .listMain .detail-container .table-container {
    flex: 1 1;
    flex-basis: 100%;
    margin: 0px 16px 8px 16px;
  }
}
.content-root .listMain .detail-container .table-container .header-section {
  display: flex;
  height: 48px;
  border-bottom: 1px solid #69697e;
}
.content-root .listMain .detail-container .table-container .header-section .item.time {
  display: flex;
  flex: 1;
  padding: 12px 0px 12px 0px;
  border-right: 1px solid #69697e;
  background-color: #373753;
}
.content-root .listMain .detail-container .table-container .header-section .item.time .header1 {
  margin-left: 12px;
  font-size: 16px;
  letter-spacing: -0.8px;
  color: #fed32f;
}
.content-root .listMain .detail-container .table-container .header-section .item.data {
  display: flex;
  flex: 2;
  padding: 12px 0px 12px 0px;
  background-color: #373753;
}
.content-root .listMain .detail-container .table-container .header-section .item.data .header2 {
  font-size: 16px;
  letter-spacing: -0.8px;
  margin-left: 20px;
  color: #fed32f;
}
.content-root .listMain .detail-container .table-container .body-section {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv {
  height: calc(100vh - 330px);
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
@media (max-width: 900px) {
  .content-root .listMain .detail-container .table-container .body-section #scrollablediv {
    height: calc(100vh - 544px);
  }
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .loding-message {
  margin: 12px;
  text-align: center;
  color: #fff;
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component .row {
  border-top: 1px solid #69697e;
  display: flex;
  height: 48px;
  background-color: #373753;
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component .row:hover {
  background-color: #4b474f;
  cursor: pointer;
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component .row .item.time {
  flex: 1;
  border-right: 1px solid #69697e;
  font-size: 14px;
  letter-spacing: -0.7px;
  padding: 14px 0px 14px 0px;
  color: #fff;
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component .row .item.time .time.detail {
  margin-left: 12px;
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component .row .item.data {
  display: flex;
  align-items: center;
  flex: 2;
  font-size: 14px;
  letter-spacing: -0.7px;
  padding: 14px 0px 14px 0px;
  color: #fff;
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component .row .item.data .item.detail {
  flex: 1;
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component .row .item.data .item.detail.frost {
  color: #dd1b52;
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component .row .item.data .item.detail.normal {
  color: #5aa664;
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component .row .item.data .item.detail.wet {
  color: #ea5e35;
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component .row:first-child {
  border-top: none;
}
.content-root .listMain .detail-container .table-container .body-section #scrollablediv .infinite-scroll-component .row:last-child {
  border-bottom: 1px solid #69697e;
}
.content-root .listMain .detail-container .table-container .notice-image {
  height: 18px;
  width: 18px;
  margin: 15px 6.3px 15px 20px;
}
@media (max-width: 900px) {
  .content-root .listMain .detail-container .table-container .notice-image {
    margin: 15px 6px 15px 19px;
  }
}
.content-root .listMain .detail-container .table-container .image-image {
  height: 18px;
  width: 18px;
  margin: 15px 12px 15px 12px;
}/*# sourceMappingURL=List.css.map */