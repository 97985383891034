@import '../../color.scss';
@import '../../constants.scss';

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 130px;
  padding-left: 40px;
  padding-right: 40px;
  @include mobile {
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;
  }
  background-color: $background;

  .logo-wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: end;

    .logo {
      height: 38px;
      width: 204px;
      @include mobile {
        height: 24px;
        width: 123px;
      }
    }

    .logo-text {
      color: $white;
      padding-left: 16px;
      font-size: 18px;
      @include mobile {
        display: none;
      }
    }
  }

  .nav {
    display: flex;
    justify-content: space-between;

    .nav-img-wrapper {
      cursor: pointer;

      .nav-img {
        width: 32px;
        height: 32px;
        margin-left: 36px;
        @include mobile {
          width: 28px;
          height: 28px;
          margin-left: 16px;
        }
      }
    }
  }
}
