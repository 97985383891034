.check-box-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 34px;
  cursor: pointer;
  height: 24px;
}
.check-box-root .check-input {
  display: none;
}
.check-box-root .custom-checkbox-box {
  display: inline-block;
}
.check-box-root .auto-login {
  margin-left: 8px;
  font-family: "AppleSDGothicNeo", sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.45px;
  text-align: center;
  color: white;
}/*# sourceMappingURL=checkbox.styles.css.map */