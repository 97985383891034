@font-face {
  font-family: "AppleSDGothicNeo-Regular";
  src: url("./fonts/AppleSDGothicNeoL.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "AppleSDGothicNeo-Medium";
  src: url("./fonts/AppleSDGothicNeoM.ttf");
  font-weight: 500;
}
* {
  margin: 0;
  padding: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-padding-after: 0;
          padding-block-end: 0;
  -webkit-padding-before: 0;
          padding-block-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  background-color: transparent;
  font-family: "AppleSDGothicNeo-Regular", sans-serif;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}/*# sourceMappingURL=core.css.map */