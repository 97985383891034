@import '../../color.scss';
@import '../../constants.scss';

@keyframes newsLineChange {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $background;
  height: 90px;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  @include mobile {
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .text-wrapper {
    margin-top: 35px;
    margin-bottom: 34px;
    height: 21px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    @include mobile {
      height: 17px;
      margin-top: 20px;
      margin-bottom: 19px;
    }

    .news-text {
      color: $white;
      font-family: 'AppleSDGothicNeo-Regular', sans-serif;
      font-size: 18px;
      letter-spacing: -0.45px;
      @include mobile {
        display: none;
      }
    }

    .bar {
      width: 1px;
      margin-left: 15px;
      margin-right: 15px;
      @include mobile {
        display: none;
      }
    }

    .text-container {
      display: flex;
      animation: newsLineChange 0.3s ease-in;
      .warning-img {
        margin-right: 6px;
        width: 20px;
        height: 20px;
        @include mobile {
          width: 18px;
          height: 18px;
        }
      }

      .name-text {
        color: $yellow;
        letter-spacing: -0.45px;
        font-family: 'AppleSDGothicNeo', sans-serif;
        font-size: 18px;
        text-align: left;

        &.frost {
          color: #dd1b52;
        }

        &.wet {
          color: #ea5e35;
        }

        &.normal {
          color: #5aa664;
        }

        @include mobile {
          font-size: 14px;
          min-width: 24px;
        }
      }

      .colon-text {
        color: $white;
        padding-left: 5px;
        padding-right: 5px;
        font-family: 'AppleSDGothicNeo', sans-serif;
        font-size: 18px;
        @include mobile {
          font-size: 14px;
        }
      }

      .location-text {
        color: $white;
        letter-spacing: -0.45px;
        font-family: 'AppleSDGothicNeo', sans-serif;
        font-size: 18px;
        @include mobile {
          font-size: 14px;
          overflow: hidden;
        }
      }
    }
  }

  .temp-wrapper {
    margin-top: 26px;
    margin-bottom: 25px;
    display: flex;
    height: 39px;

    @include mobile {
      margin-top: 14px;
      margin-bottom: 13px;
      min-width: 61px;
      height: 29px;
    }

    .temp-img {
      margin-top: 6px;
      width: 20px;
      height: 20px;
      @include mobile {
        margin-top: 4px;
        width: 18px;
        height: 18px;
      }
    }

    .temp-text {
      margin-left: 6px;
      margin-right: 7px;
      color: $yellow;
      font-size: 32px;
      letter-spacing: -0.8px;
      height: 39px;
      font-family: 'AppleSDGothicNeo', sans-serif;
      @include mobile {
        margin-right: 0;
        font-size: 24px;
        height: 29px;
      }
    }

    .temp-unit-text {
      width: 14px;
      color: $yellow;
      letter-spacing: -0.45px;
      color: $yellow;
      font-family: 'AppleSDGothicNeo', sans-serif;
      font-size: 18px;
      @include mobile {
        width: 12px;
        font-size: 14px;
      }
    }
  }
}
