.map-root {
  display: flex;
  height: calc(100vh - 220px);
  min-height: 440px;
}
@media (max-width: 900px) {
  .map-root {
    height: calc(100vh - 112px);
  }
}
.map-root .map-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 4px;
}
.map-root .map-section #map {
  width: 100%;
  height: 100%;
}
.map-root .map-section .marker-label {
  width: 79px;
  height: 32px;
  border-radius: 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-size: 14px;
  font-family: AppleSDGothicNeo-Regular;
  text-align: center;
}
.map-root .map-section .marker-label.active {
  border: solid 3px #fed32f;
}
.map-root .map-section .up-level-btn {
  position: absolute;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  bottom: 180px;
  right: 40px;
  background: #ffffff;
  z-index: 1;
}
@media (max-width: 900px) {
  .map-root .map-section .up-level-btn {
    bottom: 144px;
    right: 16px;
  }
}
.map-root .map-section .down-level-btn {
  position: absolute;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  bottom: 128px;
  right: 40px;
  background: #ffffff;
  z-index: 1;
}
@media (max-width: 900px) {
  .map-root .map-section .down-level-btn {
    bottom: 92px;
    right: 16px;
  }
}/*# sourceMappingURL=kakaomap.styles.css.map */