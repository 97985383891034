@import '../../constants';

$main: #373753;
$picker-icon-right-padding: 24px;

.content-root {
  background-color: #1d1d39;
  height: calc(100vh - 220px);
  min-height: 300px;
  display: flex;
  flex: 1;
  flex-direction: column;
  @include mobile {
    height: calc(100vh - 112px);
  }

  .listHeader {
    height: 130px;
  }
  .listFooter {
    height: 90px;
  }
  .listMain {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    .data-container {
      width: 100%;
      height: 56px;
      display: flex;
      flex-wrap: wrap;
      @include mobile {
        height: auto;
      }
      .ice-data {
        display: flex;
        align-items: center;
        flex: 10;
        margin: 0px 8px 8px 40px;
        border-radius: 4px;
        background-color: $main;
        @include mobile {
          flex: 1 1;
          flex-basis: 100%;
          margin: 0px 16px 8px 16px;
          height: 56px;
        }
        .notice-image {
          height: 18px;
          width: 18px;
          margin-left: 12px;
          margin-right: 6px;
        }
        .ice-data-detail {
          font-size: 18px;
          color: #fff;
          letter-spacing: -0.9px;
          .ice-data-title {
            &.frost {
              color: #dd1b52;
            }
            &.normal {
              color: #5aa664;
            }
            &.wet {
              color: #ea5e35;
            }
          }
          @include mobile {
            font-size: 16px;
            letter-spacing: -0.8px;
          }
        }
      }

      .calendar {
        display: flex;
        flex: 7;
        margin: 0px 48px 8px 0px;
        border-radius: 4px;
        overflow: hidden;
        background-color: #1d1d39;
        @include mobile {
          flex: 1 1;
          flex-basis: 100%;
          margin: 0px 16px 8px 16px;
          height: 56px;
        }
        .calendar-input {
          display: flex;
          flex: 1;
          @include mobile {
            margin-right: 0;
          }
          input[type='date']::-webkit-datetime-edit-text {
            -webkit-appearance: none;
            display: none;
          }

          input[type='date']::-webkit-datetime-edit-month-field {
            -webkit-appearance: none;
            display: none;
          }

          input[type='date']::-webkit-datetime-edit-day-field {
            -webkit-appearance: none;
            display: none;
          }

          input[type='date']::-webkit-datetime-edit-year-field {
            -webkit-appearance: none;
            display: none;
          }

          .inputBox-label {
            display: flex;
            flex: 1;
            position: relative;
            .inputBox {
              flex: 1;
              border-radius: 4px;
              padding-right: $picker-icon-right-padding;
              background-color: $main;
              &::-webkit-calendar-picker-indicator {
                background: transparent;
                color: transparent;
                cursor: pointer;
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                height: 100%;
              }
            }
            .imageDate {
              position: absolute;
              bottom: 12px;
              right: 14px;
              width: 24px;
              height: 24px;
              pointer-events: none;
              @include mobile {
                bottom: 16px;
              }
            }
            .choicedDate {
              position: absolute;
              left: 12px;
              bottom: 12px;
              font-size: 18px;
              letter-spacing: -0.9px;
              color: #fff;
              pointer-events: none;
              @include mobile {
                font-size: 16px;
                letter-spacing: -0.8px;
                bottom: 18px;
              }
            }
          }
        }
        .calendar-searchButton {
          width: 76px;
          border-radius: 4px;
          background-color: $main;
          font-size: 18px;
          letter-spacing: -0.9px;
          color: #fed32f;
          @include mobile {
            display: none;
          }
        }
      }
    }

    .detail-container {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      width: 100%;
      @include mobile {
        height: auto;
      }
      .detailImage {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 10;
        margin: 0px 8px 8px 40px;
        border-radius: 4px;
        background-color: $main;
        overflow: hidden;
        .image-fill {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        @include mobile {
          flex: 1 1;
          flex-basis: 100%;
          margin: 0px 16px 8px 16px;
          height: 242px;
          .imageEmpty {
            width: 64px;
            height: 47px;
          }
        }
      }
      .table-container {
        display: flex;
        flex-direction: column;
        flex: 7;
        margin: 0px 48px 8px 0px;
        border-radius: 4px;
        background-color: $main;
        overflow: hidden;
        @include mobile {
          flex: 1 1;
          flex-basis: 100%;
          margin: 0px 16px 8px 16px;
        }
        .header-section {
          display: flex;
          height: 48px;
          border-bottom: 1px solid #69697e;
          // align-items: center;
          .item.time {
            display: flex;
            flex: 1;
            padding: 12px 0px 12px 0px;
            border-right: 1px solid #69697e;
            background-color: $main;
            .header1 {
              margin-left: 12px;
              font-size: 16px;
              letter-spacing: -0.8px;
              color: #fed32f;
            }
          }
          .item.data {
            display: flex;
            flex: 2;
            padding: 12px 0px 12px 0px;
            background-color: $main;
            .header2 {
              font-size: 16px;
              letter-spacing: -0.8px;
              margin-left: 20px;
              color: #fed32f;
            }
          }
        }
        .body-section {
          display: flex;
          flex-direction: column;
          flex: 1;

          #scrollablediv {
            height: calc(100vh - 330px);
            overflow: auto;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
              display: none; /* Chrome, Safari, Opera*/
            }
            @include mobile {
              height: calc(100vh - 544px);
            }
            .loding-message {
              margin: 12px;
              text-align: center;
              color: #fff;
            }
            .infinite-scroll-component {
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
              &::-webkit-scrollbar {
                display: none; /* Chrome, Safari, Opera*/
              }
              .row {
                border-top: 1px solid #69697e;
                display: flex;
                height: 48px;
                background-color: $main;
                &:hover {
                  background-color: #4b474f;
                  cursor: pointer;
                }
                .item.time {
                  flex: 1;
                  border-right: 1px solid #69697e;
                  font-size: 14px;
                  letter-spacing: -0.7px;
                  padding: 14px 0px 14px 0px;
                  color: #fff;
                  .time.detail {
                    margin-left: 12px;
                  }
                }
                .item.data {
                  display: flex;
                  align-items: center;
                  flex: 2;
                  font-size: 14px;
                  letter-spacing: -0.7px;

                  padding: 14px 0px 14px 0px;
                  color: #fff;
                  .item.detail {
                    flex: 1;

                    &.frost {
                      color: #dd1b52;
                    }
                    &.normal {
                      color: #5aa664;
                    }
                    &.wet {
                      color: #ea5e35;
                    }
                  }
                }
                &:first-child {
                  border-top: none;
                }

                &:last-child {
                  border-bottom: 1px solid #69697e;
                }
              }
            }
          }
        }

        .notice-image {
          height: 18px;
          width: 18px;
          margin: 15px 6.3px 15px 20px;
          @include mobile {
            margin: 15px 6px 15px 19px;
          }
        }
        .image-image {
          height: 18px;
          width: 18px;
          margin: 15px 12px 15px 12px;
        }
      }
    }
  }
}
