.login-root {
  display: flex;
  flex-direction: column;
  min-height: 660px;
  height: 100vh;
  background-color: #1d1d39;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.login-root .login-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 420px;
  background-color: #1d1d39;
}
@media (max-width: 900px) {
  .login-root .login-container {
    width: 100%;
  }
}
.login-root .login-container .login-logo {
  text-align: center;
}
.login-root .login-container .login-logo .logo-image {
  width: 249px;
  height: 48px;
}
@media (max-width: 900px) {
  .login-root .login-container .login-logo .logo-image {
    width: 156px;
    height: 30px;
  }
}
.login-root .login-container .title {
  margin-top: 28px;
  margin-bottom: 92px;
  height: 24px;
  font-family: "AppleSDGothicNeo", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: center;
  color: #fed32f;
}
@media (max-width: 900px) {
  .login-root .login-container .title {
    margin-top: 24px;
    margin-bottom: 56px;
  }
}
@media (max-width: 900px) {
  .login-root .login-container .login-form {
    margin-left: 16px;
    margin-right: 16px;
  }
}
.login-root .login-container .login-form .login-formtag {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.login-root .login-container .login-form .login-formtag .input-container .login-input-box {
  width: 100%;
  height: 56px;
  background-color: #373753;
  padding: 18px 16px;
  font-size: 18px;
  border-radius: 4px;
  color: #ffffff;
}
.login-root .login-container .login-form .login-formtag .input-container .login-input-box::-moz-placeholder {
  color: #ffffff;
}
.login-root .login-container .login-form .login-formtag .input-container .login-input-box:-ms-input-placeholder {
  color: #ffffff;
}
.login-root .login-container .login-form .login-formtag .input-container .login-input-box::placeholder {
  color: #ffffff;
}
.login-root .login-container .login-form .login-formtag .login-btn {
  height: 56px;
  background-color: #fed32f;
  font-size: 18px;
  font-weight: normal;
  font-family: "AppleSDGothicNeo", sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  color: black;
  border-radius: 4px;
  margin-top: 40px;
  cursor: pointer;
}
.login-root .login-setting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 900px) {
  .login-root .login-setting {
    margin-left: 16px;
    margin-right: 16px;
  }
}
.login-root .login-bottom {
  flex: 1;
  display: flex;
  align-items: flex-end;
  margin-bottom: 60px;
}
.login-root .login-bottom img {
  width: 222px;
  height: 36px;
}
@media (max-width: 900px) {
  .login-root .login-bottom {
    margin-bottom: 20px;
  }
  .login-root .login-bottom img {
    width: 180px;
    height: 30px;
  }
}
.login-root .login-top {
  flex: 1;
}/*# sourceMappingURL=login.styles.css.map */