$mobile: 900px;
$tablet: 760px;
$desktop: 1024px;

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: calc($mobile + 1px)) and (max-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: calc($tablet + 1px)) {
    @content;
  }
}
