@import '../../constants';

$main: #1d1d39;
.login-root {
  display: flex;
  flex-direction: column;
  min-height: 660px;
  height: 100vh; //viewport 높이의 100%임
  background-color: $main;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .login-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 420px;
    background-color: $main;
    @include mobile {
      width: 100%;
    }
    .login-logo {
      text-align: center;

      .logo-image {
        width: 249px;
        height: 48px;
        @include mobile {
          width: 156px;
          height: 30px;
        }
      }
    }

    .title {
      margin-top: 28px;
      margin-bottom: 92px;
      @include mobile {
        margin-top: 24px;
        margin-bottom: 56px;
      }
      height: 24px;
      font-family: 'AppleSDGothicNeo', sans-serif;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.5px;
      text-align: center;
      color: #fed32f;
    }

    .login-form {
      @include mobile {
        margin-left: 16px;
        margin-right: 16px;
      }
      .login-formtag {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .input-container {
          .login-input-box {
            width: 100%;
            height: 56px;
            background-color: #373753;
            padding: 18px 16px;
            font-size: 18px;
            border-radius: 4px;
            color: #ffffff;

            &::placeholder {
              color: #ffffff;
            }
          }
        }

        .login-btn {
          height: 56px;
          background-color: #fed32f;
          font-size: 18px;
          font-weight: normal;
          font-family: 'AppleSDGothicNeo', sans-serif;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.9px;
          color: black;
          border-radius: 4px;
          margin-top: 40px;
          cursor: pointer;
        }
      }
    }
  }
  .login-setting {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include mobile {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
  .login-bottom {
    flex: 1;
    display: flex;
    align-items: flex-end;
    margin-bottom: 60px;
    img {
      width: 222px;
      height: 36px;
    }
    @include mobile {
      img {
        width: 180px;
        height: 30px;
      }
      margin-bottom: 20px;
    }
  }

  .login-top {
    flex: 1;
  }
}
